(function () {
    document.addEventListener("DOMContentLoaded", () => {
        const latestFeedEl = document.getElementById('latest-feed');

        var latestFeedSlider = new Swiper(latestFeedEl, {
            speed: 750,
            spaceBetween: 16,
            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                1024: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 2,
                },
                360: {
                    slidesPerView: 1,
                }
            }
        });

    });
}());